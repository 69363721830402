<div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="{{ globalSvc.gutterSm }}">
  <mat-spinner *ngIf="busy" diameter="16"></mat-spinner>
  <basic-button id="{{ id }}-cancel" [type]="ButtonType.Stroked" [disabled]="disabled || busy" (click)="cancel.emit()">
    {{ cancelButtonText ?? "NGX_LIB_CANCEL" | translate }}
  </basic-button>
  <basic-button
    id="{{ id }}-save"
    color="primary"
    [type]="ButtonType.Flat"
    [disabled]="saveDisabled || disabled || busy"
    (click)="submit.emit()"
  >
    {{ saveButtonText ?? "NGX_LIB_SAVE" | translate }}
  </basic-button>
</div>
