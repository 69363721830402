<mat-form-field>
  <mat-label>{{ label | translate }}</mat-label>
  <textarea
    id="{{ id }}-input"
    matInput
    [errorStateMatcher]="errorStateMatcher"
    [formControl]="formControl"
    (change)="setValueAndEmit($event.target.value)"
    [required]="required"
  ></textarea>
  <help-icon matIconSuffix [tooltip]="tooltip"></help-icon>
  <mat-error [fieldError]="formControl"></mat-error>
</mat-form-field>
