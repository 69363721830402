import { ChangeDetectionStrategy, Component, HostBinding, Input } from "@angular/core";

export enum FeatureLabelType {
  BETA = "beta",
}

@Component({
  selector: "feature-label",
  template: ` {{ "NGX_LIB_FEATURE_LABEL_TYPE_" + type | translate }} `,
  styleUrls: ["./feature-label.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeatureLabelComponent {
  @HostBinding(`class.feature-label-type-${FeatureLabelType.BETA}`) get isBeta() {
    return this.type === FeatureLabelType.BETA;
  }

  @Input() type: FeatureLabelType = FeatureLabelType.BETA;
}
