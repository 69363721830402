<mat-form-field>
  <mat-label>{{ label | translate }}</mat-label>
  <input
    matInput
    id="{{ id }}-input"
    [errorStateMatcher]="errorStateMatcher"
    [formControl]="formControl"
    [value]="value ?? ''"
    [type]="type"
    (change)="setValueAndEmit($event.target.value)"
    [required]="required"
    class="hide-arrows-widget"
  />
  <help-icon matIconSuffix [tooltip]="tooltip"></help-icon>
  <mat-error [fieldError]="formControl"></mat-error>
</mat-form-field>
