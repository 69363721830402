import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { TranslateModule } from "@ngx-translate/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { BasicButtonModule } from "../basic-button/basic-button.module";
import { ButtonBarComponent } from "./button-bar.component";

@NgModule({
  declarations: [ButtonBarComponent],
  imports: [CommonModule, FlexLayoutModule, TranslateModule, MatProgressSpinnerModule, BasicButtonModule],
  exports: [ButtonBarComponent, FlexLayoutModule],
})
export class ButtonBarModule {}
