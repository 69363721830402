<mat-form-field>
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <input
    matInput
    id="{{ id }}-input"
    type="date"
    value="{{ valueString }}"
    min="{{ minimumDateString }}"
    max="{{ maximumDateString }}"
    (change)="valueString = $event.target.value"
    [required]="required"
  />
  <mat-error [fieldError]="formControl"></mat-error>
</mat-form-field>
