<mat-form-field>
  <mat-label>{{ label | translate }}</mat-label>
  <mat-select
    id="{{ id }}"
    [errorStateMatcher]="errorStateMatcher"
    multiple
    [required]="required"
    [ngModel]="value"
    (selectionChange)="onSelectionChange($event.value)"
    (openedChange)="openedChange($event)"
    panelClass="panelClass-{{ !inputOptions.length || busy ? 'busy' : '' }}"
  >
    <ng-container *ngIf="showSearch">
      <mat-option>
        <ngx-mat-select-search
          ngModel
          (ngModelChange)="optionFilter = $event"
          placeholderLabel="{{ searchPlaceholderText ?? 'NGX_LIB_SEARCH_PLACEHOLDER' | translate }}"
          noEntriesFoundLabel="{{ noSearchResultText ?? 'NGX_LIB_SEARCH_NO_RESULTS' | translate }}"
        >
          <inf-icon ngxMatSelectSearchClear icon="clear"></inf-icon>
        </ngx-mat-select-search>
      </mat-option>
      <button class="search-all-button" mat-flat-button (click)="toggleAll()">
        {{ searchAllText ?? "NGX_LIB_SEARCH_ALL" | translate }}
      </button>
    </ng-container>
    <mat-option
      *ngFor="let inputOption of optionsToDisplay; trackBy: trackOptions"
      id="{{ id }}-option-{{ inputOption.optionValue }}"
      [value]="inputOption.optionValue"
    >
      <div [class]="optionClasses">
        <div *ngIf="inputOption.iconHTML" [innerHtml]="inputOption.iconHTML"></div>
        {{ inputOption.optionLabel | translate }} {{ inputOption.subLabel }}
      </div>
    </mat-option>
    <mat-option *ngIf="!inputOptions.length || busy">
      <div *ngIf="busy" class="loading-spinner">
        <mat-spinner [diameter]="20"></mat-spinner>
      </div>
      <div *ngIf="!busy">
        {{ "NGX_LIB_NO_OPTIONS" | translate }}
      </div>
    </mat-option>
    <div *ngIf="showMoreThanThresholdOptionsMessage" class="mat-select-footnote">
      {{ showingRowsText }}
    </div>
  </mat-select>
  <help-icon matIconSuffix [tooltip]="tooltip"></help-icon>
  <mat-error [fieldError]="formControl"></mat-error>
</mat-form-field>
