<mat-form-field>
  <mat-label>{{ label | translate }}</mat-label>
  <input
    matInput
    id="{{ id }}-input"
    [errorStateMatcher]="errorStateMatcher"
    [formControl]="formControl"
    [readonly]="readonly"
    [value]="value || ''"
    (change)="setValueAndEmit($event.target.value)"
    [required]="required"
    [type]="showPassword ? 'text' : 'password'"
  />
  <mat-icon *ngIf="showEyeIcon" matIconSuffix id="{{ id }}-eye-icon" (click)="showPassword = !showPassword">{{
    showPassword ? "visibility" : "visibility_off"
  }}</mat-icon>
  <help-icon matIconSuffix [tooltip]="tooltip"></help-icon>
  <mat-error [fieldError]="formControl"></mat-error>
</mat-form-field>
