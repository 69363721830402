<mat-form-field class="tag-input-list">
  <mat-label>{{ label }}</mat-label>
  <mat-chip-grid
    id="{{ id }}-input"
    #tagList
    multiple
    [formControl]="formControl"
    [required]="required"
    [value]="value"
    (change)="setValueAndEmit(value)"
  >
    <mat-chip-row
      *ngFor="let tag of value"
      [disabled]="readonlyValuesSet.has(tag.value)"
      [removable]="!readonlyValuesSet.has(tag.value)"
      [disableRipple]="readonlyValuesSet.has(tag.value)"
      [value]="tag"
      (removed)="deselectTag(tag)"
    >
      {{ tag.name }}
      <ng-container *ngIf="tag.value">={{ tag.value }}</ng-container>
      <mat-icon matChipRemove *ngIf="!readonlyValuesSet.has(tag)">close</mat-icon>
    </mat-chip-row>
    <input
      id="{{ id }}-new-tag-input"
      [autocomplete]="'one-time-code'"
      placeholder="{{ createLabel }}"
      #newTagInput
      [matChipInputFor]="tagList"
      [matChipInputAddOnBlur]="true"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="createAndSelectTag($event)"
    />
    <help-icon matIconSuffix [tooltip]="tooltip"></help-icon>
    <mat-error [fieldError]="formControl"></mat-error>
  </mat-chip-grid>
</mat-form-field>
